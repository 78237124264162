const {EditorState, convertFromRaw} = require("draft-js");
const {format, parse, formatDistance} = require("date-fns");
const {L, toDateFns} = require("./locale/locale");
const {markdownToDraft} = require("markdown-draft-js");

function createDownload(name, data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.remove();
}

function formatDuration (seconds) {
    let minutes = Math.floor((seconds / 60) % 60).toString();
    let hours = Math.floor(seconds / 60 / 60).toString();
    seconds = Math.floor(seconds % 60).toString();
    return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
}

function upperFirstChars(str) {
    return str.split(" ")
        .map(block => block.charAt(0).toUpperCase() + block.substr(1))
        .join(" ");
}

function initEditor(str, markdown = false) {
    try {
        let draft;
        if (markdown) {
            draft = markdownToDraft(str);
        } else {
            let jsonObj = (typeof str === "object") ? str : JSON.parse(str);
            if (jsonObj) {
                draft = convertFromRaw(jsonObj);
            }
        }
        return EditorState.createWithContent(draft);
    } catch (ignored) {
        console.log("Invalid editor state:", ignored, str);
    }

    return EditorState.createWithText(str);
}

const formatSize = (size) => {
    const suffixes = ["B", "KiB", "MiB", "GiB", "TiB"];
    let i = 0;
    for (; i < suffixes.length && size >= 1024; i++) {
        size /= 1024.0;
    }

    if (i === 0 || Math.round(size) === size) {
        return size + " " + suffixes[i];
    } else {
        return size.toFixed(1) + " " + suffixes[i];
    }
}

const formatDate = (apiDate) => {
    if (!(apiDate instanceof Date)) {
        apiDate = parse(apiDate, API_DATETIME_FORMAT, new Date());
    }
    return format(apiDate, L("YYY/MM/dd"));
}

const formatDistanceString = (date) => {
    return formatDistance(date, new Date(), { addSuffix: true, locale: toDateFns() });
}

const getParameter = (key) => {
    const url = new URL(window.location);
    if (url.searchParams.has(key)) {
        return decodeURIComponent(url.searchParams.get(key));
    } else {
        return null;
    }
}

const removeParameter = (key) => {
    const url = new URL(window.location);
    url.searchParams.delete(key);
    window.history.replaceState(null, '', url);
}

const encodeText = (str) => {
    return Uint8Array.from(str, c => c.charCodeAt(0));
}

const decodeText = (buffer) => {
    return String.fromCharCode(...new Uint8Array(buffer));
}

const baseUrl = () => {
    return window.location.protocol + "//" + window.location.host;
}

const calculateCVSSScore = (string) => {

    if (string) {
        let attributes = string.split("/").reduce(function(map, entry) {
            const [key, value] = entry.split(":");
            map[key] = value;
            return map;
        }, {});

        let scopeChanged = (attributes["S"] || "U") === "C";
        const metricValues = {
            // Attack Vector: Network, Adjacent, Local, Physical
            AV: {N: 0.85, A: 0.62, L: 0.55, P: 0.2},
            // Attack complexity: Low, High
            AC: {L: 0.77, H: 0.44},
            // Privileges Required: None, Low, High
            PR: {N: 0.85, L: scopeChanged ? 0.68 : 0.62, H: scopeChanged ? 0.5 : 0.27},
            // User Interaction: None, Required
            UI: {N: 0.85, R: 0.62},
            // Confidentiality / Integrity / Availability: None, High, Low
            C: {N: 0, H: 0.56, L: 0.22},
            I: {N: 0, H: 0.56, L: 0.22},
            A: {N: 0, H: 0.56, L: 0.22},
        };

        const getMetric = (key, defaultValue=null) => {
            let metric = metricValues[key] || null;
            if (metric) {
                let value = attributes[key] || defaultValue;
                if (value && metric.hasOwnProperty(value)) {
                    return metric[value]
                }
            }
            return null;
        }

        let scope = attributes["S"] || "U";
        let confidentiality = getMetric("C", "N");
        let integrity = getMetric("I", "N");
        let availability = getMetric("A", "N");
        let attackVector = getMetric("AV");
        let attackComplexity = getMetric("AC");
        let privilegesRequired = getMetric("PR");
        let userInterAction = getMetric("UI");

        // required fields
        if (attackVector && attackComplexity && privilegesRequired && userInterAction) {

            let iss = 1.0 - ( (1.0 - confidentiality) * (1.0 - integrity) * (1.0 - availability) );
            let impact = null;
            if (scope === "U") { // Unchanged
                impact = 6.42 * iss;
            } else if (scope === "C") { // Changed
                impact = 7.52 * (iss - 0.029) - 3.25 * Math.pow(iss - 0.02, 15);
            }

            if (impact && impact > 0) {
                let exploitability = 8.22 * attackVector * attackComplexity * privilegesRequired * userInterAction;
                let factor = (scope === "C" ? 1.08 : 1.0);
                return Math.ceil(Math.min(factor * (impact + exploitability), 10.0) * 10.0) / 10.0;
            } else {
                return 0;
            }
        }
    }

    return null;
}


const API_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
const API_DATE_FORMAT = "yyyy-MM-dd";

module.exports = { createDownload, upperFirstChars, initEditor,
    API_DATETIME_FORMAT, API_DATE_FORMAT, formatSize, formatDate, formatDuration, formatDistanceString,
    getParameter, removeParameter, encodeText, decodeText, baseUrl,
    calculateCVSSScore
};