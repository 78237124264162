import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import {L} from "../locale/locale";

export default function AlertDialog(props) {

    let open = props.open || false;
    let title = props.title || "Untitled";
    let text = props.text || "Alert Text";
    let onClose = props.onClose || function () { };

    return <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>{ title }</DialogTitle>
        <DialogContent>
            <DialogContentText>
                { text }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color={"primary"} autoFocus>
                {L("Close")}
            </Button>
        </DialogActions>
    </Dialog>
}