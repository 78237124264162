import React from "react";
import {Link as RouterLink} from "react-router-dom";

export default class LocaleGerman {
    constructor() {
        this.code = "de_DE";
        this.name = "Deutsch Standard";
        this.entries = {

            // Login
            "Username": "Benutzername",
            "Password": "Passwort",
            "Signing in": "Einloggen",
            "Sign In": "Einloggen",
            "Forgot password?": "Passwort vergessen?",
            "Don't have an account? Sign Up": "Noch keinen Account? Registrieren",
            "Remember me": "Eingeloggt bleiben",

            // Menu
            "Settings": "Einstellungen",
            "Logout": "Ausloggen",
            "Overview": "Startseite",
            "Access": "Zugang",
            "Machines": "Maschinen",
            "Progress": "Fortschritt",
            "Administration": "Administration",
            "Tools & Resources": "Tools & Ressourcen",
            "Vulnerabilities": "Sicherheitslücken",
            "Reports": "Reports",
            "Projects": "Projekte",

            // Overview
            "Welcome to": "Willkommen zum",
            "Start Hacking": "Hacking beginnen",
            "Flag Submission": "Flags einreichen",
            "for": "für",
            "How does it work?": "Wie funktioniert es?",
            "Generate an Access Pack": "Access-Pack generieren",
            "Connect to OpenVPN": "Verbindung zu OpenVPN",
            "OVERVIEW_ENTRY_TEXT": "Das Security Lab ist eine Umgebung zum Testen und Verbessern deiner praktischen Cyber Security Skills. Mit einer Reihe von Maschinen " +
                "kannst du deine Skills, wie zum Beispiel Fuzzing, Enumerieren, Exploiten, Pivoting und Privilege Escalation, trainieren. Zusätzlich " +
                "findest du hier Tools und Resourcen, welche beim Absolvieren des Security Labs hilfreich sein können.",
            "OVERVIEW_ENTRY_TEXT_EXT": <>Das Security Lab bietet unseren Mitarbeitern die Möglichkeit, praktische Cyber Security Skills zu testen und zu verbessern.
                Für Sie dient diese Plattform als direkter Kontakt zu unseren Experten, um sicher Dateien auszutauschen. Ihre aktiven Projekte finden Sie im Reiter:&nbsp;
                <RouterLink to={"/projects"}>Projekte</RouterLink></>,
            "OVERVIEW_ACCESS_PACK_TEXT": "Bevor du dich zu dem OpenVPN Server verbinden kannst, musst du ein Access-Pack generieren.\n" +
                "Danach kannst du es jederzeit erneut herunterladen oder ein neues generieren, wodurch das alte Access-Pack invalidiert wird.",
            "OVERVIEW_CONNECT_OPENVPN": "Für die Verbindung um OpenVPN Server wird der OpenVPN Client benötigt. Für mehr Informationen besuche:",
            "OVERVIEW_START_HACKING": <>Gehe zur <RouterLink to={"/machines"}>Maschinenübersicht</RouterLink>, wähle das Ziel aus, was du angreifen möchtest, und starte die Maschine. Die IP-Adresse wird
                angezeigt, sobald die Maschine erfolgreich gestartet ist. Wenn du irgendein Tool oder eine Resource brauchst, kannst du sie <RouterLink to={"/tools"}>hier</RouterLink> finden. Viel Spaß!</>,
            "OVERVIEW_FLAGS": "Auf jeder Maschine sind zwei Flags platziert, die es zu erlangen. Diese sind üblicherweise an folgenden Orten zu finden:",
            "OVERVIEW_FLAGS_2": "Sobald du die Flags gefunden hast, kannst du sie auf der Maschinenseite einreichen und du wirst belohnt.",

            // Machine Overview
            "Machine Overview": "Maschinen Übersicht",
            "Submit a new machine": "Neue Maschine importieren",
            "Drop a zip-file containing the Vagrantfile": "Zip-Datei mit einem Vagrantfile hochladen",
            "Public Machine": "Öffentliche Maschine",
            "Submit": "Absenden",
            "Invalid arguments": "Ungültige Eingaben",
            "Please provide exactly one zip file.": "Bitte wähle genau eine zip-Datei aus.",
            "successful": "erfolgreich",
            "failed": "fehlgeschlagen",
            "Upload": "Hochladen",
            "Close": "Schließen",
            "Copy to Clipboard": "In die Zwischenablage kopieren",
            "Save to File": "In Datei speichern",
            "Submitting... This can take a while": "Hochladen... das kann einige Zeit dauern",

            // Difficulty
            "Easy": "Einfach",
            "Medium": "Medium",
            "Hard": "Schwer",
            "Insane": "Sehr Schwer",

            // Machine Table
            "Legend": "Legende",
            "Start": "Starten",
            "Stop": "Stoppen",
            "Submit a flag": "Lösung einreichen",
            "Submit Flag": "Lösung einreichen",
            "Start a machine": "Maschine starten",
            "Stop a machine": "Maschine stoppen",
            "Reset a machine": "Maschine zurücksetzen",
            "Operating System": "Betriebssystem",
            "Difficulty": "Schwierigkeit",
            "Address": "Adresse",
            "Active For": "Aktiv für",
            "Controls": "Steuerung",
            "Error starting machine": "Fehler beim Starten der Maschine",
            "Error stopping machine": "Fehler beim Stoppen der Maschine",
            "Error fetching machines": "Fehler beim Auflisten der Maschinen",
            "Loading": "Laden",
            "Offline": "Offline",
            "Refresh": "Aktualisieren",
            "Congratulations!": "Glückwunsch!",
            "You submitted a correct user flag.": "Du hast eine richtige Userflag eingereicht",
            "You submitted a correct root flag.": "Du hast eine richtige Rootflag eingereicht",
            "Error submitting flag": "Fehler beim Absenden der Flag",
            "Submit the flag and try your luck.": "Sende eine Flag ab und versuche dein Glück",
            "Flag": "Flag",
            "Cancel": "Abbrechen",

            // Progress
            "Progress Overview": "Fortschrittsübersicht",
            "Global Progress": "Gesamtfortschritt",
            "Personal Progress": "Eigener Fortschritt",
            "Name": "Name",
            "Score": "Punktzahl",
            "Ranking": "Bestenliste",
            "Error loading submissions": "Fehler beim Laden des Fortschritts",

            // Connection Pack
            "Download": "Herunterladen",
            "Request": "Anfordern",
            "Error downloading connection pack": "Fehler beim Herunterladen des Connection-Packs",
            "Error requesting connection pack": "Fehler beim anfordern des Connection-Packs",

            // Report
            "m/d/Y H:i:ss": "d.m.Y H:i:ss"
        };
    }
}