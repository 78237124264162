import React from "react";
import {Link as RouterLink} from "react-router-dom";

export default class LocaleEnglish {
    constructor() {
        this.code = "en_US";
        this.name = "American English";
        this.entries = {

            // Overview
            "OVERVIEW_ENTRY_TEXT": "The Security Lab is an environment to test and improve your practical Cyber Security skills. With a set of machines\n" +
                "you can train various skills like fuzzing and enumerating, exploiting, pivoting or escalation of privileges. Additionally\n" +
                "you can find resources and tools which might be helpful to complete the Security Lab.",
            "OVERVIEW_ENTRY_TEXT_EXT": <>The Security Lab offers our employees to test and improve practical Cyber Security skills.
                This platform serves as a direct contact to our experts for you, for exchanging files securely. You can find your active projects under the tab:&nbsp;
                <RouterLink to={"/projects"}>Projects</RouterLink></>,
            "OVERVIEW_ACCESS_PACK_TEXT": "Before you can connect to the OpenVPN Server, you need to generate an access pack once.\n" +
                "After that, you can re-download it again everytime or just request a new one, which would invalidate previously generated access packs.",
            "OVERVIEW_CONNECT_OPENVPN": "To connect to the OpenVPN server, you will need to install a OpenVPN Client.\n" +
                "For more information visit either:",
            "OVERVIEW_START_HACKING": <>Go to the <RouterLink to={"/machines"}>machines page</RouterLink>, select a target you want to attack and start the machine. The IP-Address will be
                shown, whenever the machine started successfully. If you are looking for any tool or resource, you can find them <RouterLink to={"/tools"}>here</RouterLink>. Have fun!</>,
            "OVERVIEW_FLAGS": "On each machine there are two secrets you have to leak. These are usually located in:",
            "OVERVIEW_FLAGS_2": "Once you obtained the secrets, you can submit them on the machine page and you will be rewarded.",
        };
    }
};