import React, {useState, lazy, Suspense} from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar, Box,
    Container,
    Divider,
    Drawer,
    IconButton, List,
    ListItem, ListItemIcon, ListItemText, ListSubheader,
    Toolbar,
    Typography
} from "@material-ui/core";

import {L, useLanguageModule} from "./locale/locale";

import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import FeedbackIcon from '@material-ui/icons/Feedback';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';
import BugReportIcon from '@material-ui/icons/BugReport';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PeopleIcon from '@material-ui/icons/People';
import CodeIcon from '@material-ui/icons/Code';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon  from '@material-ui/icons/ArrowDropUp';
import GitHubIcon from '@material-ui/icons/GitHub';

import clsx from "clsx";
import AlertDialog from "./elements/alertDialog";

import {version} from '../package.json';

const LabOverview = lazy(() => import('./views/overview'));
const LabMachines = lazy(() => import('./views/machines'));
const SettingsView = lazy(() => import('./views/settings'));
const ToolsView = lazy(() => import('./views/tools'));
const ProgressView = lazy(() => import('./views/progress'));
const EditMachine = lazy(() => import('./views/editmachine'));
const ReportsView = lazy(() => import('./views/reports'));
const EditReportView = lazy(() => import('./views/editreport'));
const EditTargetView = lazy(() => import('./views/edittarget'));
const VulnerabilityView = lazy(() => import('./views/vulnerabilities'));
const EditVulnerability = lazy(() => import('./views/editvulnerability'));
const ProjectView = lazy(() => import('./views/projects'));
const EditProjectView = lazy(() => import('./views/editproject'));
const UserView = lazy(() => import('./views/userview'));
const UserListView = lazy(() => import('./views/users'));
const CodeScanQueue = lazy(() => import('./views/codescan'));
const SystemLogs = lazy(() => import('./views/systemlogs'));
const ResourcePHP = lazy(() => import('./resources/php'));

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    fixedHeight: {
        height: 240,
    },
    menuActive: {
        borderLeftColor: theme.palette.primary.main,
        borderLeftStyle: "solid",
        borderLeftWidth: 5,
        paddingLeft: 16,
        backgroundColor: "#eef"
    },
    menuInactive: {
        paddingLeft: 21
    },
    expandButton: {
        maxWidth: 20,
    },
    subItem: {
        paddingLeft: theme.spacing(5),
        "& span": {
            fontSize: "0.9rem",
        }
    },
    subMenuActive: {
        "& span": {
            fontWeight: "bold"
        }
    }
}));

const SecurityLabLanguageModule = {
    "de_DE": {
        "YYY/MM/dd": "dd.MM.yyyy",
        "Cancel": "Abbrechen",
        "Confirm": "Bestätigen",
        "OK": "OK",
    }
}

export default function SecurityLab(props) {

    let api = props.api;
    useLanguageModule(SecurityLabLanguageModule);
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [categoryExpanded, setCategoryExpanded] = useState({});
    const [alert, setAlert] = useState(null);
    const handleDrawerOpen = () => { setOpen(true); };
    const handleDrawerClose = () => { setOpen(false); };
    const onLogout = () => { props.onLogout(() => history.push("/login")) };

    if (!api.loggedIn) {
        if (window.location.pathname === "/dashboard") {
            return <Redirect to={"/login"} />
        } else {
            return <Redirect to={"/login?next=" + encodeURIComponent(window.location.pathname)} />
        }
    }

    const onAlertClose = () => {
        setAlert({ ...alert, open: false });
    }

    const openAlert = (title, text, type) => {
        setAlert({ open: true, title: title, text: text, type: type });
    }

    let menuItems = { user: [], secLab: [] };
    let menu = {
        user: [
            { title: L("Settings"), icon: <SettingsIcon />, path: "/settings" },
            { title: L("Users"), icon: <PeopleIcon />, path: "/users",
                visible: api.hasPermission("user/fetch") },
            { title: L("System Logs"), icon: <FeedbackIcon />, visible: api.hasPermission("logs/get"),
                path: "/logs" },
            { title: L("Administration"), icon: <SettingsInputComponentIcon />, visible: api.isAdmin(),
                onClick: () => { window.location.href = "/admin" } },
            { title: L("Logout"), icon: <ExitToAppIcon />, onClick: onLogout }
        ],
        secLab: [
            { title: L("Overview"), icon: <HomeIcon />, path: "/overview" },
            { title: L("Machines"), icon: <StorageIcon />, path: "/machines", subpath: /\/machine\/.*/,
                visible: api.hasPermission("machine/*") },
            // { title: L("Progress"), icon: <TimeLineIcon />, path: "/progress",
            //    visible: api.hasPermission("machine/*") },
            { title: L("Tools & Resources"), icon: <BuildIcon />, path: "/tools", subpath: /\/resources\/.*/,
                visible: api.hasPermission("tools/*"), entries: [
                    { title: L("PHP"), icon: <CodeIcon />, path: "/resources/php" },
                    // { title: L("JavaScript"), icon: <CodeIcon />, path: "/resources/javascript" },
                ]
            },
            { title: L("Reports"), icon: <DescriptionIcon />, path: "/reports", subpath: /\/report\/.*/,
                visible: api.hasPermission("report/*") },
            { title: L("Vulnerabilities"), icon: <BugReportIcon />, path: "/vulnerabilities", subpath: /\/vulnerability\/.*/,
                visible: api.hasPermission("vulnerability/*") },
            { title: L("Projects"), icon: <AccountTreeIcon />, path: "/projects", subpath: /\/project\/.*/ },
            { title: L("Commit Review"), icon: <GitHubIcon />, path: "/codescan",
                visible: api.hasPermission("webhook/status")
            }
        ]
    }

    const createMenuItem = (category, item) => {
        let key = (item.path ? item.path.substr(1) : item.title);
        let onClick = item.onClick || (() => { history.push(item.path) });
        let active = (item.path && item.path === currentPath) || (item.subpath && currentPath.match(item.subpath));
        if (active) {
            activeItem = item;
        }

        const onToggle = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setCategoryExpanded({...categoryExpanded, [key]: !categoryExpanded[key] });
        }

        menuItems[category].push(
            <ListItem button onClick={onClick} key={category + "-" + key}
                      onClickCapture={() => item.entries && item.entries.length > 0 &&
                          setCategoryExpanded({...categoryExpanded, [key]: true })}
                      className={active ? classes.menuActive : classes.menuInactive}>
                <ListItemIcon>{ item.icon }</ListItemIcon>
                <ListItemText primary={item.title} />
                { item.entries && item.entries.length > 0
                    ? <IconButton onClick={onToggle} className={classes.expandButton}>
                        {categoryExpanded[key] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </IconButton>
                    : <></>
                }
            </ListItem>
        );

        if (item.entries) {
            for (const subItem of item.entries) {
                let subActive = (subItem.path && subItem.path === currentPath);
                if (subActive && !categoryExpanded.hasOwnProperty(key)) {
                    setCategoryExpanded({ ...categoryExpanded, [key]: true });
                }

                if (!!categoryExpanded[key]) {
                    let subKey = subItem.path.substr(1);
                    let subOnClick = item.onClick || (() => { history.push(subItem.path) });
                    let subStyles = active ? { paddingLeft: 35 } : { };

                    menuItems[category].push(
                        <ListItem button key={category + "-" + key + "-" + subKey}
                                  onClick={subOnClick}
                                className={clsx(
                                    classes.subItem,
                                    active ? classes.menuActive : classes.menuInactive,
                                    subActive && classes.subMenuActive)}
                                style={subStyles}>
                            <ListItemIcon>{ subItem.icon }</ListItemIcon>
                            <ListItemText primary={subItem.title} />
                        </ListItem>
                    );
                }
            }
        }
    };

    let activeItem = { title: "Dashboard" };
    let currentPath = history.location.pathname === "/" ? "/overview" : history.location.pathname;
    for (const category of Object.keys(menu)) {
        for (const item of Object.values(menu[category])) {
            if (!item.hasOwnProperty("visible") || item.visible) {
                createMenuItem(category, item);
            }
        }
    }

    let controlObj = {
        ...props,
        closeAlert: onAlertClose,
        openAlert: openAlert
    };

    return <div className={classes.root}>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        { activeItem.title }
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open} >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <MenuIcon />
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <ListSubheader inset>Security Lab</ListSubheader>
                    { menuItems.secLab }
                </List>
                <Divider/>
                <List>
                    <ListSubheader inset>User</ListSubheader>
                    { menuItems.user }
                </List>
                <Divider/>
                <ListSubheader inset><b>Version: {version}</b></ListSubheader>

            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Box p={2}>
                    <Container maxWidth={"lg"} className={classes.container}>
                       <Suspense fallback={<div>Loading... </div>}>
                           <Switch>
                               <Route path={"/machines"} render={(p) => <LabMachines  {...controlObj} {...p} />} />
                               <Route path={"/machine/:id/edit"} render={(p) => <EditMachine {...controlObj} {...p} />} />
                               <Route path={"/progress"} render={(p) => <ProgressView {...controlObj} {...p} />} />
                               <Route path={"/settings"} render={(p) => <SettingsView {...controlObj} {...p} />} />
                               <Route path={"/tools"} render={(p) => <ToolsView {...controlObj} {...p} />} />
                               <Route path={"/resources/php/:topic?"} render={(p) => <ResourcePHP {...controlObj} {...p} />} />
                               <Route path={"/reports"} render={(p) => <ReportsView {...controlObj} {...p} />} />
                               <Route path={"/report/new"} render={(p) => <EditReportView {...controlObj} {...p} />} />
                               <Route path={"/report/:id/edit"} render={(p) => <EditReportView {...controlObj} {...p} />} />
                               <Route path={"/report/:reportId/target/new"} render={(p) => <EditTargetView {...controlObj} {...p} />} />
                               <Route path={"/report/:reportId/target/:targetId/edit"} render={(p) => <EditTargetView {...controlObj} {...p} />} />
                               <Route path={"/vulnerabilities"} render={(p) => <VulnerabilityView {...controlObj} {...p} />} />
                               <Route path={"/vulnerability/:id/edit"} render={(p) => <EditVulnerability {...controlObj} {...p} />} />
                               <Route path={"/vulnerability/new"} render={(p) => <EditVulnerability {...controlObj} {...p} />} />
                               <Route path={"/projects"} render={(p) => <ProjectView {...controlObj} {...p} />} />
                               <Route path={"/project/:id/:tab?"} render={(p) => <EditProjectView {...controlObj} {...p} />} />
                               <Route path={"/user/:id"} render={(p) => <UserView {...controlObj} {...p} />} />
                               <Route path={"/users"} render={(p) => <UserListView {...controlObj} {...p} />} />
                               <Route path={"/codescan"} render={(p) => <CodeScanQueue {...controlObj} {...p} />} />
                               <Route path={"/logs"} render={(p) => <SystemLogs {...controlObj} {...p} />} />
                               <Route path={"/(overview|dashboard)?"} exact={true} render={(p) => <LabOverview {...controlObj} {...p} />} />
                               <Route path={"*"} render={() => <b>Not Found</b>} />
                           </Switch>
                       </Suspense>
                    </Container>
                </Box>
            </main>
        <AlertDialog open={alert?.open} title={alert?.title} text={alert?.text} onClose={onAlertClose} />
    </div>
}