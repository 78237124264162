{
  "name": "security-lab",
  "version": "1.1.10",
  "private": true,
  "dependencies": {
    "@material-ui/core": "^4.11.4",
    "@material-ui/icons": "^4.11.2",
    "@material-ui/lab": "^4.0.0-alpha.58",
    "@material-ui/styles": "^4.11.4",
    "@monaco-editor/react": "^4.3.1",
    "axios": "^0.21.1",
    "babel-polyfill": "^6.26.0",
    "chart.js": "^3.4.1",
    "clsx": "^1.1.1",
    "date-fns": "^2.22.1",
    "draft-js": "^0.11.7",
    "markdown-draft-js": "^2.4.0",
    "material-ui-dropzone": "^3.5.0",
    "react": "^17.0.2",
    "react-chartjs-2": "^3.0.4",
    "react-dom": "^17.0.2",
    "react-draft-wysiwyg": "bitbucket:ilumesoft/react-draft-wysiwyg",
    "react-flatpickr": "^3.10.7",
    "react-hooks-sse": "^2.0.0",
    "react-markdown": "^7.1.1",
    "react-native": "^0.65.0",
    "react-number-format": "^4.6.4",
    "react-router-dom": "^5.2.0",
    "react-scripts": "^4.0.3",
    "sprintf-js": "^1.1.2",
    "streamsaver": "^2.0.5",
    "web-streams-polyfill": "^3.2.0"
  },
  "devDependencies": {
    "parcel": "^2.1.1"
  },
  "source": "src/index.js",
  "scripts": {
    "build": "mkdir -p ../js/seclab/ && rm -f dist/* ../js/seclab/* && npx parcel build && mv dist/* ../js/seclab/",
    "deploy": "ssh seclab@seclab.myilume.de 'rm -f /var/www/seclab.myilume.de/js/seclab/*' && scp -r ../js/seclab/* seclab@seclab.myilume.de:/var/www/seclab.myilume.de/js/seclab/",
    "debug": "HTTPS=true react-scripts start"
  },
  "eslintConfig": {
    "extends": [
      "react-app",
      "react-app/jest"
    ]
  },
  "@parcel/bundler-default": {
    "minBundles": 1,
    "minBundleSize": 3000,
    "maxParallelRequests": 1
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "proxy": "https://seclab.myilume.de"
}
