import 'babel-polyfill';
import axios from "axios";
import {initLocale} from "./locale/locale";
import {createDownload} from "./util";

export default class API {

    constructor() {
        this.loggedIn = false;
        this.user = { };
    }

    csrfToken() {
        return this.loggedIn ? this.user.session.csrf_token : null;
    }

    hasGroup(groupName) {
        if (this.loggedIn && this.user.groups) {
            let userGroups = Object.values(this.user.groups);
            return userGroups.includes(groupName);
        } else {
            return false;
        }
    }

    isAdmin() {
        return this.hasGroup("Administrator");
    }

    hasPermission(method) {
        if (!this.permissions) {
            return false;
        }

        for (const permission of this.permissions) {
            if (method.endsWith("*") && permission.toLowerCase().startsWith(method.toLowerCase().substr(0, method.length - 1))) {
                return true;
            } else if (method.toLowerCase() === permission.toLowerCase()) {
                return true;
            }
        }

        return false;
    }

    async apiCall(method, params={}, expectBinary=false, options = {}) {
        const csrf_token = this.csrfToken();
        if (csrf_token) {
            if (params instanceof FormData) {
                params.append("csrf_token", csrf_token);
            } else {
                params.csrf_token = csrf_token;
            }
        }

        let response
        try {
            let config = {...options, method: "post", url: "/api/" + method, data: params, withCredentials: true};
            if (expectBinary) config.responseType = "arraybuffer";
             response = await axios(config);
        } catch (e) {
            let responseData = e.response.data;
            try {
                if (typeof responseData !== 'object') {
                    responseData = JSON.parse(responseData);
                }

                if (responseData.msg === "You are not logged in.") {
                    document.location.reload();
                } else if (responseData.msg === "2FA-Authorization is required") {
                    document.location.href = "/login";
                }

                return responseData;
            } catch (e) {
                return {success: false, msg: "Server error: " + responseData};
            }
        }

        let contentDisposition = response.headers["content-disposition"]
        if (contentDisposition && contentDisposition.toLowerCase().startsWith("attachment;")) {
            let fileName = /filename="?([^"]*)"?/;
            createDownload(fileName.exec(contentDisposition)[1], new Blob([response.data]));
            return { success: true, msg: "" };
        }

        let data = response.data;
        if (expectBinary) {
            try {
                data = String.fromCharCode.apply(null, new Uint8Array(data));
                data = JSON.parse(data);
            } catch(e) {
                data = { success: false, msg: "Server returned an invalid json: " + data };
            }
        }

        if (!data.success) {
            if (data.msg === "You are not logged in.") {
                document.location.reload();
            } else if (data.msg === "2FA-Authorization is required") {
                document.location.href = "/login";
            }
        }

        return data;
    }

    // User API

    async fetchUser() {
        let response = await axios.get("/api/user/info");
        let data = response.data;
        this.user = data["user"];
        this.loggedIn = data["loggedIn"];
        this.permissions = data["permissions"] ? data["permissions"].map(s => s.toLowerCase()) : [];
        initLocale(this.user.language.code);
        return data && data["success"] && data["loggedIn"];
    }

    async getUser(id) {
        return this.apiCall("user/get", { id: id });
    }

    async fetchUsers() {
        return this.apiCall("user/fetch");
    }

    async login(username, password, rememberMe) {
        return this.apiCall("user/login", { username: username, password: password, stayLoggedIn: rememberMe })
    }

    async logout() {
        return this.apiCall("user/logout");
    }

    async updateProfile(username=null, fullName=null, password=null, confirmPassword = null, oldPassword = null) {
        return this.apiCall("user/updateProfile", { username: username, fullName: fullName,
            password: password, confirmPassword: confirmPassword, oldPassword: oldPassword });
    }

    async uploadGPG(pubkey) {
        return this.apiCall("user/importGPG", { pubkey: pubkey });
    }

    async confirmGpgToken(token) {
        return this.apiCall("user/confirmGPG", { token: token });
    }

    async removeGPG(password) {
        return this.apiCall("user/removeGPG", { password: password });
    }

    async downloadGPG(userId) {
        return this.apiCall("user/downloadGPG", { id: userId }, true);
    }

    async uploadPicture(file, scale=1.0) {
        const formData = new FormData();
        formData.append("scale", scale);
        formData.append("picture", file, file.name);
        return this.apiCall("user/uploadPicture", formData);
    }

    async removePicture() {
        return this.apiCall("user/removePicture");
    }

    // Project API
    async fetchProjects() {
        return this.apiCall("project/fetch");
    }

    async fetchProject(id) {
        return this.apiCall("project/fetch", { id: id });
    }

    async createProject(name, start = null, end = null) {
        return this.apiCall("project/create", { name: name, start: start, end: end });
    }

    async updateProject(id, name, start = null, end = null) {
        return this.apiCall("project/update", { id: id, name: name, start: start, end: end });
    }

    async updateProjectPreferences(id, receiveNotifications) {
        return this.apiCall("project/updatePreferences", { id: id, receiveNotifications: receiveNotifications });
    }

    async deleteProject(id) {
        return this.apiCall("project/delete", { id: id });
    }

    async addProjectMember(id, userId) {
        return this.apiCall("project/addMember", { id: id, userId: userId });
    }

    async removeProjectMember(id, userId) {
        return this.apiCall("project/removeMember", { id: id, userId: userId });
    }

    async searchProjectUser(id, searchString) {
        return this.apiCall("project/searchUser", { id: id, searchString: searchString });
    }

    // Project File API
    async fetchFileRestrictions() {
        return this.apiCall("project/getRestrictions");
    }

    async fetchProjectFiles(projectId) {
        return this.apiCall("project/listFiles", { id: projectId });
    }

    async uploadFiles(projectId, files, directoryId = null, onProgress = null, overwrite = false) {
        const formData = new FormData();
        formData.append("id", projectId);
        formData.append("overwrite", overwrite);

        if (directoryId) {
            formData.append("directoryId", directoryId);
        }

        for (let i = 0; i < files.length; i++) {
            formData.append("file" + i, files[i], files[i].name);
        }

        return this.apiCall("project/uploadFile", formData, false,{ onUploadProgress: onProgress });
    }

    async deleteFile(projectId, entryId) {
        return this.apiCall("project/deleteFile", { id: projectId, entryId: entryId });
    }

    async downloadFile(projectId, file, onProgress = null) {
        // return this.apiCall("project/downloadFile", { id: projectId, fileId: file }, true);
        // return this.fetchCall("project/downloadFile", {id: projectId, fileId: file}, { onProgress: onProgress });
        let a = document.createElement("a");
        let params = { id: projectId, fileId: file, csrf_token: this.csrfToken() };
        a.href = "/api/project/downloadFile?" + new URLSearchParams(params).toString();
        a.click();
        return { success: true, msg: "" };
    }

    async downloadAllFiles(projectId) {
        // return this.apiCall("project/downloadAllFiles", { id: projectId }, true);
        let a = document.createElement("a");
        let params = { id: projectId, csrf_token: this.csrfToken() };
        a.href = "/api/project/downloadAllFiles?" + new URLSearchParams(params).toString();
        a.click();
        return { success: true, msg: "" };
    }

    async createDirectory(projectId, name, directoryId = null) {
        return this.apiCall("project/createDirectory", { id: projectId, name: name, directoryId: directoryId });
    }

    async renameFile(projectId, fileId, name) {
        return this.apiCall("project/renameFile", { id: projectId, name: name, fileId: fileId });
    }

    async moveFile(projectId, fileId, directoryId = null) {
        return this.apiCall("project/moveFile", { id: projectId, fileId: fileId, destinationId: directoryId });
    }

    async getFileAccessLog(projectId) {
        return this.apiCall("project/getAccessLog", { projectId: projectId });
    }

    // Project Message API
    async getProjectMessages(projectId) {
        return this.apiCall("project/getMessages", { id: projectId });
    }

    async sendProjectMessage(projectId, message) {
        return this.apiCall("project/sendMessage", { id: projectId, message: message });
    }

    async editProjectMessage(projectId, messageId, message) {
        return this.apiCall("project/editMessage", { id: projectId, messageId: messageId, message: message });
    }

    async deleteProjectMessage(projectId, messageId) {
        return this.apiCall("project/deleteMessage", { id: projectId, messageId: messageId });
    }

    async getUnreadProjectMessages(projectId) {
        return this.apiCall("project/getUnreadMessages", { id: projectId });
    }

    // Project Timeline API
    async addProjectTimelineEntry(projectId, title, message = "", timestamp = null) {
        return this.apiCall("project/addTimelineEntry", { id: projectId, title: title, message: message, timestamp: timestamp });
    }

    async removeProjectTimelineEntry(projectId, id) {
        return this.apiCall("project/removeTimelineEntry", { id: projectId, entryId: id });
    }

    // OpenVPN API
    async requestOvpn() {
        return this.apiCall("openvpn/request");
    }

    async downloadOvpn(format) {
        return this.apiCall("openvpn/download", { format: format });
    }

    // Machine API
    async getMachines() {
        return this.apiCall("machine/status");
    }

    async getMachine(id) {
        return this.apiCall("machine/status", { id: id });
    }

    async startMachine(id) {
        return this.apiCall("machine/start", { id : id });
    }

    async stopMachine(id) {
        return this.apiCall("machine/stop", { id : id });
    }

    async resetMachine(id) {
        return this.apiCall("machine/reset", { id : id });
    }

    async refreshMachine(id) {
        return this.apiCall("machine/refresh", { id : id });
    }

    async deleteMachine(id) {
        return this.apiCall("machine/delete", { id : id });
    }

    async submitFlag(id, flag) {
        return this.apiCall("machine/submitFlag", { id : id, flag: flag });
    }

    async uploadMachine(file, isPublic=true) {

        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("public", isPublic.toString());

        // let timeout = 20 * 60 * 1000; // 20 minutes
        // let options = { timeout: timeout };
        return this.apiCall("machine/upload", formData, false);
    }

    openSSE() {
        return new EventSource("/api/machine/subscribe", { withCredentials: true });
    }

    // Language API
    async getLanguages() {
        return this.apiCall("language/get");
    }

    async setLanguage(code) {
        return this.apiCall("language/set", { langCode: code });
    }

    // Tools API
    async searchTools(flat = false) {
        return this.apiCall("tools/search", { flat: flat });
    }

    async addTool(categoryId, title, description, link, tags = "") {
        return this.apiCall("tools/add", { category: categoryId, title: title, description: description, link: link, tags: tags })
    }

    // Progress API
    async getProgress() {
        return this.apiCall("machine/getProgress");
    }

    // Report API
    async getReports() {
        return this.apiCall("report/fetch");
    }

    async getReport(id) {
        return this.apiCall("report/fetch", { id: id });
    }

    async createReport(report) {
        return this.apiCall("report/create", { report: report });
    }

    async generateReport(id, regenerate = false) {
        return this.apiCall("report/generate", { id: id, regenerate: regenerate }, true);
    }

    async deleteReport(id) {
        return this.apiCall("report/delete", { id: id });
    }

    async updateReport(report, comment = null) {
        return this.apiCall("report/update", { report: report, comment: comment });
    }

    async uploadReportImage(reportId, file) {
        const formData = new FormData();
        formData.append("reportId", reportId);
        formData.append("file", file, file.name);
        return this.apiCall("report/uploadImage", formData);
    }

    async getReportImages(reportId) {
        return this.apiCall("report/getUploadedImages", { reportId: reportId });
    }

    async deleteReportImage(reportId, imageId) {
        return this.apiCall("report/deleteUploadedImage", { reportId: reportId, id: imageId });
    }

    async deleteTarget(reportId, targetId) {
        return this.apiCall("report/deleteTarget", { reportId: reportId, targetId: targetId });
    }

    async getTarget(reportId, targetId) {
        return this.apiCall("report/getTarget", { reportId: reportId, targetId: targetId });
    }

    async addTarget(reportId, targetObj) {
        return this.apiCall("report/addTarget", { reportId: reportId, target: targetObj });
    }

    async updateTarget(reportId, targetObj) {
        return this.apiCall("report/updateTarget", { reportId: reportId, target: targetObj });
    }

    async deleteHistory(reportId, historyId) {
        return this.apiCall("report/deleteHistory", { reportId: reportId, historyId: historyId });
    }

    async updateHistory(reportId, historyId, comment) {
        return this.apiCall("report/updateHistory", { reportId: reportId, historyId: historyId, comment: comment });
    }

    // Vulnerability API
    async getVulnerabilityTypes() {
        return this.apiCall("vulnerability/getTypes");
    }

    async deleteVulnerability(id) {
        return this.apiCall("vulnerability/delete", { id: id });
    }

    async getVulnerability(id) {
        return this.apiCall("vulnerability/get", { id: id });
    }

    async updateVulnerability(id, name, shortName, description, severity) {
        return this.apiCall("vulnerability/update", { id: id, name: name, shortName: shortName, description: description, severity: severity });
    }

    async addVulnerability(name, shortName, description, severity) {
        return this.apiCall("vulnerability/add", { name: name, shortName: shortName, description: description, severity: severity });
    }

    // News API
    async getNews() {
        return this.apiCall("news/get");
    }

    async deleteNews(id) {
        return this.apiCall("news/delete", { id: id });
    }

    async editNews(id, title, text, internalOnly = false) {
        return this.apiCall("news/edit", { id: id, title: title, text: text, internalOnly: internalOnly });
    }

    async publishNews(title, text, internalOnly = false) {
        return this.apiCall("news/publish", { title: title, text: text, internalOnly: internalOnly });
    }

    // 2FA API
    async confirmTOTP(code) {
        return this.apiCall("tfa/confirmTotp", { code: code });
    }

    async remove2FA(password) {
        return this.apiCall("tfa/remove", { password: password });
    }

    async verifyTotp2FA(code) {
        return this.apiCall("tfa/verifyTotp", { code: code });
    }

    async verifyKey2FA(credentialID, clientDataJSON, authData, signature) {
        return this.apiCall("tfa/verifyKey", { credentialID: credentialID, clientDataJSON: clientDataJSON, authData: authData, signature: signature })
    }

    async register2FA(clientDataJSON = null, attestationObject = null) {
        return this.apiCall("tfa/registerKey", { clientDataJSON: clientDataJSON, attestationObject: attestationObject });
    }

    // API-Key API
    async getApiKeys(showActiveOnly = false) {
        return this.apiCall("apiKey/fetch", { showActiveOnly: showActiveOnly });
    }

    async createApiKey() {
        return this.apiCall("apiKey/create");
    }

    async revokeKey(id) {
        return this.apiCall("apiKey/revoke", { id: id });
    }

    // Webhook / Code Scanning API
    async getCommitReviewJobs(showAll = false) {
        return this.apiCall("webhook/status", { showAll: showAll });
    }

    async restartCommitReviewJob(jobId) {
        return this.apiCall("webhook/restartJob", { id: jobId });
    }

    async cancelCommitReviewJob(jobId) {
        return this.apiCall("webhook/cancelJob", { id: jobId });
    }

    // System logs
    async fetchLogs(since = null, logLevel = "debug") {
        return this.apiCall("logs/get", { since: since, severity: logLevel });
    }
};