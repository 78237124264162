import * as ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import API from "./api";
import React from "react";
import LoginForm from "./views/login";
import SecurityLab from "./seclab";
import {CircularProgress, CssBaseline, ThemeProvider} from "@material-ui/core";
import {currentLocale} from "./locale/locale";
import {createTheme} from "@material-ui/core/styles";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.api = new API();
        this.state = {
            loaded: false,
            user: { },
            error: "",
            locale: currentLocale
        }
    }

    onUpdateLocale() {
        this.setState({ ...this.state, locale: currentLocale })
    }

    onTotp2FA(code, callback) {
        this.setState({ ...this.state, error: "" });
        return this.api.verifyTotp2FA(code).then((res) => {
            if (res.success) {
                this.api.fetchUser().then(() => {
                    this.setState({ ...this.state, user: res });
                    callback(res);
                })
            } else {
                this.setState({ ...this.state, error: res.msg });
                callback(res);
            }
        });
    }

    onKey2FA(credentialID, clientDataJson, authData, signature, callback) {
        this.setState({ ...this.state, error: "" });
        return this.api.verifyKey2FA(credentialID, clientDataJson, authData, signature).then((res) => {
            if (res.success) {
                this.api.fetchUser().then(() => {
                    this.setState({ ...this.state, user: res });
                    callback(res);
                })
            } else {
                this.setState({ ...this.state, error: res.msg });
                callback(res);
            }
        });
    }

    onLogin(username, password, rememberMe, callback) {
        this.setState({ ...this.state, error: "" });
        return this.api.login(username, password, rememberMe).then((res) => {
            if (res.success) {
                this.api.fetchUser().then(() => {
                    this.setState({ ...this.state, user: res });
                    callback(res);
                })
            } else {
                this.setState({ ...this.state, error: res.msg });
                callback(res);
            }
        });
    }

    onLogout(callback) {
        this.api.logout().then(() => {
            this.api.loggedIn = false;
            this.setState({ ...this.state, user: { } })
            if (callback) callback();
        });
    }

    createRoutes() {
        let controlObj = {
            api: this.api, error: this.state.error, user: this.state.user,
            locale: this.state.locale,
            onLogin: this.onLogin.bind(this),
            onTotp2FA: this.onTotp2FA.bind(this),
            onKey2FA: this.onKey2FA.bind(this),
            onLogout: this.onLogout.bind(this),
            onUpdateLocale: this.onUpdateLocale.bind(this)
        };

        return <Router>
            <Switch>
                <Route path={"/login"} render={(props) => <LoginForm {...controlObj} {...props} />}/>
                <Route path={"*"} render={(props) => <SecurityLab {...controlObj} {...props} />} />
            </Switch>
        </Router>
    }

    render() {
        if (!this.state.loaded) {
            this.api.fetchUser().then(() => {
                this.setState({ ...this.state, loaded: true, user: this.api.user });
            });

            return <>Loading… <CircularProgress size={15} /></>;
        } else {
            return this.createRoutes();
        }
    };
}

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: "#272e80",
        },
        secondary: {
            main: "#DC143C"
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ]
    },
    "overrides": {
        MuiBreadcrumbs: {
            li: {
                "& > a": {
                    textDecoration: "none",
                },
                "& > a:visited": {
                    color: "#7c7c7c",
                },
            },
        }
    }
});

ReactDOM.render(<ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
</ThemeProvider>, document.getElementById('root'));